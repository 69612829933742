import { supportsPassiveEvents } from 'detect-it';

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initialize);
} else {
	initialize();
}

var unloads = [];

function unload () {
	/*
	* Multiple listeners has been created. Remove them when site closes.
	*/
	unloads.forEach((item, i) => {
		console.log('unloading:', item.event);
		item.elem.removeEventListener(item.event, item.func);
	});
}

function initialize () {
	toggleSubmenus();
	closeDropdownsOnOutsideClick();
	listenScroll();
	toggleMenu();
}

var menuCTAButton = document.querySelector('.menu-join-button');
var logo = document.querySelector('.logo-holder svg');
var logoHolder = document.querySelector('.logo-holder');
var menuToggle = document.querySelector('.menu-toggle');

function toggleSubmenus () {
	var toggleDropDowns = document.querySelectorAll('[data-dropdown-owner-id]');

	toggleDropDowns.forEach((toggleDropDown, i) => {
		toggleDropDown.addEventListener('click', toggle);
		toggleDropDown.addEventListener('mouseenter', open);
		toggleDropDown.addEventListener('mouseleave', close);
		unloads.push({event: 'click', func: toggle, elem: toggleDropDown});
		unloads.push({event: 'mouseenter', func: open, elem: toggleDropDown});
		unloads.push({event: 'mouseleave', func: close, elem: toggleDropDown});
	});
}

function toggle (evt) {
	var dropdown = document.querySelector('.menu-dropdown[data-dropdown-id="'+ this.dataset.dropdownOwnerId +'"]');
	if (window.innerWidth < 992) {
		// mobile
		console.log('mobile!');
		openMobileSubMenu(dropdown, this);
	} else {
		// desktop
		if (dropdown.hasClass('visible')) {
			dropdown.classList.remove('visible');
			this.setAttribute('aria-expanded', 'false');
			setTimeout(() => {
				dropdown.classList.add('invisible');
			}, 500);
		} else {
			dropdown.classList.remove('invisible');
			dropdown.classList.add('visible');
			this.setAttribute('aria-expanded', 'true');
		}
	}
}

function open (evt) {
	console.log('Trigger');
	var dropdown = document.querySelector('.menu-dropdown[data-dropdown-id="'+ this.dataset.dropdownOwnerId +'"]');
	if (window.innerWidth < 992) {
		// mobile
		// has to be clicked (in toggle-function);
	} else {
		dropdown.classList.remove('invisible');
		dropdown.classList.add('visible');
		this.setAttribute('aria-expanded', 'true');
	}
}

function close (evt) {
	var dropdown = document.querySelector('.menu-dropdown[data-dropdown-id="'+ this.dataset.dropdownOwnerId +'"]');
	if (window.innerWidth < 992) {
		// mobile
		// has to be clicked (in toggle-function);
	} else {
		dropdown.classList.remove('visible');
		this.setAttribute('aria-expanded', 'false');
		// setTimeout(() => {
		// 	dropdown.classList.add('invisible');
		// }, 500);
	}
}

function openMobileSubMenu (dropdown, self) {
	document.querySelectorAll('.nav-item').forEach((item, i) => {
		item.classList.add('hidden');
	});
	dropdown.classList.remove('invisible'); // make it focusable
	dropdown.classList.remove('hidden'); // make it not hidden
	dropdown.classList.add('visible'); // animate it
	menuCTAButton.classList.add('hidden');
	// logo.classList.add('hidden');
	menuToggle.classList.add('mobile-sub-open');
	self.setAttribute('aria-expanded', 'true');
}

function closeMobileSubMenu () {
	var dropdown = document.querySelector('.menu-dropdown.visible');
	document.querySelectorAll('.nav-item').forEach((item, i) => {
		item.classList.remove('hidden');
		if (item.getAttribute('aria-expanded')) {
			item.setAttribute('aria-expanded', 'false');
		}
	});
	dropdown.classList.add('hidden');
	dropdown.classList.remove('visible');
	menuCTAButton.classList.remove('hidden');
	// logo.classList.remove('hidden');
	menuToggle.classList.remove('mobile-sub-open');
	setTimeout(() => {
		dropdown.classList.add('invisible'); // make it not focusable
	}, 500);
}

function closeDropdownsOnOutsideClick () {
	document.body.addEventListener('click', function (e) {
		if (window.innerWidth < 992) {
			// mobile
			/*
			* The dropdowns closes differentrly on mobile. Handled in the function closeMobileSubMenu.
			*/
		} else {
			// desktop
			const menuDropdownClicked = e.target.closest('.menu-dropdown');
			if (!menuDropdownClicked && (!e.target.hasClass('nav-item') && !e.target.closest('.nav-item'))) {
				// close dropdowns
				const openDropdowns = document.querySelectorAll('.menu-dropdown.visible');
				openDropdowns.forEach((item, i) => {
					item.classList.remove('visible');
					setTimeout(() => {
						dropdown.classList.add('invisible'); // make it not focusable
					}, 500);
				});
				document.querySelectorAll('.nav-item[aria-expanded]').forEach((item, i) => {
					item.setAttribute('aria-expanded', 'false');
				});
			}
		}
	});
}

function toggleMenu () {
	var menuToggle = document.querySelector('.menu-toggle');
	var menu = document.querySelector('.menu');
	var logo = document.querySelector('.logo-holder svg');
	var logoPurpleParts = logo.querySelectorAll('path[fill="#880e4f"]');
	menuToggle.addEventListener('click', function (evt) {
		if (menuToggle.hasClass('mobile-sub-open')) {
			// mobile sub menu is open. close it.
			closeMobileSubMenu();
		} else if (menu.hasClass('open')) {
			menu.classList.remove('open');
			menuToggle.classList.remove('bg-white', 'open');
			document.body.classList.remove('noscroll');
			logoPurpleParts.forEach((part, i) => {
				part.setAttribute('fill', '#880e4f');
			});
		} else {
			menu.classList.add('open');
			menuToggle.classList.add('bg-white', 'open');
			document.body.classList.add('noscroll');
			logoPurpleParts.forEach((part, i) => {
				part.setAttribute('fill', '#ffffff');
			});
		}
	});
}

function listenScroll () {
	var header = document.querySelector('.main-header');
	window.addEventListener('scroll', function () {
		if (window.pageYOffset < 40) {
			if (header.hasClass('scrolled')) {
				header.classList.remove('scrolled');
			}
		} else {
			if (!header.hasClass('scrolled')) {
				header.classList.add('scrolled');
			}
		}
	}, supportsPassiveEvents ? { passive: true, capture: false } : false);
}
