if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initialize);
} else {
	initialize();
}

/*
* A simple tab script for hiding/showing tabs based on their parameters
*/

var unloads = [];

function initialize () {
	var triggers = document.querySelectorAll('[data-tab-trigger]');
	triggers.forEach((trigger, i) => {
		unloads.push({event: 'click', func: onClick, elem: trigger});
		trigger.addEventListener('click', onClick);
	});

	unloads.push({event: 'beforeunload', func: unload, elem: window});
	window.addEventListener("beforeunload", unload);
}

function unload () {
	unloads.forEach((item, i) => {
		console.log('unloading:', item.event);
		item.elem.removeEventListener(item.event, item.func);
	});
}

function onClick (evt) {
	// console.log('click:', evt.target.dataset, evt.target.dataset.tabTrigger);

	var container = evt.target.closest('[data-tab-container]');

	var triggers = container.querySelectorAll('[data-tab-trigger]');
	triggers.forEach((trigger, i) => {
		trigger.classList.remove('active');
	});
	evt.target.classList.add('active');

	var swiperSlideTabs = container.querySelectorAll('[data-tab].swiper-slide');
	var tabsAreSlides = swiperSlideTabs && swiperSlideTabs.length;

	var closeAll = container.querySelectorAll('[data-tab]');
	closeAll.forEach((item, i) => {
		if (item.hasClass('active')) {
			item.classList.remove('active', 'swiper-slide', 'swiper-slide-next', 'swiper-slide-prev', 'swiper-slide-active');
		}
	});

	var tabs = container.querySelectorAll('[data-tab]');
	if (tabs && tabs.length) {
		// console.log('swiperSlideTabs:', tabsAreSlides);
		tabs.forEach((tab, i) => {
			var values = tab.dataset.tab.split(',');
			// console.log(values);
			if (values.includes(evt.target.dataset.tabTrigger)) {
				tab.classList.add('active');
				if (tabsAreSlides) {
					tab.classList.add('swiper-slide');
				}
			}
		});
		// console.log('updateSwiper');
		var swipers = container.querySelectorAll('.swiper-container');
		swipers.forEach((swiper, i) => {
			swiper.swiper.updateSlides();
			swiper.swiper.update();
		});
	} else {
		console.warn('Missing tab(s):', evt.target.dataset.tabTrigger);
	}

	// var opens = container.querySelectorAll('[data-tab="' + evt.target.dataset.tabTrigger + '"]');
	// if (opens && opens.length) {
	// 	opens.forEach((open, i) => {
	// 		console.log('open:', open);
	// 		open.classList.add('active');
	// 	});
	// } else {
	// 	console.warn('Missing tab(s):', evt.target.dataset.tabTrigger);
	// }
}
