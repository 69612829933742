import { supportsPassiveEvents } from 'detect-it';

/*
* A simple filter script for hiding/showing the certain-staffmembers based on their parameters.
*/

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initialize);
} else {
	initialize();
}

var unloads = [];
var toggle = document.body.querySelector('.filter-toggle');
var filtersCont = document.body.querySelector('.selected-filters-container');

var filter, filters = null;

var chosenLocationFilters = [];
var chosenCatFilters = [];

function initialize () {
	if (toggle) {
		filter = document.body.querySelector('.filters');
		filters = filter.querySelectorAll('[type="checkbox"]');

		unloads.push({event: 'click', func: toggleFilter, elem: toggle});
		toggle.addEventListener('click', toggleFilter);

		if (filters && filters.length) {
			filters.forEach((item, i) => {
				unloads.push({event: 'change', func: filterResults, elem: item});
				item.addEventListener('change', filterResults);
			});
		}

		unloads.push({event: 'click', func: checkClickOutsideFilter, elem: document.body});
		document.body.addEventListener('click', checkClickOutsideFilter);
	}

	unloads.push({event: 'beforeunload', func: unload, elem: window});
	window.addEventListener("beforeunload", unload);
}

function unload () {
	unloads.forEach((item, i) => {
		// console.log('unloading staff:', item.event);
		item.elem.removeEventListener(item.event, item.func);
	});
}

function toggleFilter () {
	if (filter.hasClass('visible')) {
		/*
		* add hidden tag aswell after visible is removed to make sure it does not take space, and messes with horizontal scroll when no items matches filters
		*/
		filter.classList.remove('visible');
		setTimeout(function () {
			filter.classList.add('hidden');
		}, 300);
	} else {
		// open it.
		filter.classList.remove('hidden');
		setTimeout(function () {
			filter.classList.add('visible');
		}, 10);
	}
}

function filterResults (e) {
	/*
	* When checking/unchecking a filter in the filter
	*/
	// console.log('Triggered:', e.target.value, e.target.name, e.target.checked);

	if (e.target.checked) {
		if (filtersCont) {
			var button = document.createElement("button");                 // Create a <li> node
			var img = document.createElement("img");                 // Create a <li> node
			var textnode = document.createTextNode(e.target.dataset.title);         // Create a text node
			img.setAttribute('src', '/static-assets/icons/cancel.svg');
			button.setAttribute('data-filtered-id', e.target.value);
			button.setAttribute('class', 'filtered-item-button');
			button.appendChild(textnode);
			button.appendChild(img);
			filtersCont.appendChild(button);
			button.onclick = function (evt) {
				button.remove();
				e.target.checked = false;
				// console.log(e.target);
				f(e);
			};
		}
	} else {
		var filtered = filtersCont.querySelector('button[data-filtered-id="'+ e.target.value +'"]');
		if (filtered) {
			filtered.remove()
		}
	}

	f(e);
}

function f (e) {
	var copy = [];
	if (e.target.name === 'locations') {
		copy = [...chosenLocationFilters];
	} else if (e.target.name === 'subcats') {
		copy = [...chosenCatFilters];
	}
	var i = copy.indexOf(e.target.value);
	if (i > -1) {
		// remove it
		copy.splice(i, 1);
	} else {
		// add it
		copy.push(e.target.value);
	}

	if (e.target.name === 'locations') {
		chosenLocationFilters = [...copy];
	} else if (e.target.name === 'subcats') {
		chosenCatFilters = [...copy];
	}
	// console.log({chosenCatFilters, chosenLocationFilters});

	if (!chosenCatFilters.length && !chosenLocationFilters.length) {
		/*
		* No filters applied.
		* Make all active items (active in tab) visible.
		*/
		// console.log('no filters');
		var filtered = document.body.querySelectorAll('.staff-item.filtered');
		filtered.forEach((item, i) => {
			item.classList.remove('filtered');
		});

		return;
	}

	/*
	* User has set filters.
	*/
	var selection = document.body.querySelectorAll('.staff-item');
	var postSelectionId = [];
	if (chosenLocationFilters && chosenLocationFilters.length) {
		// user has selected a location filter.
		var locationsFilters = [];
		selection.forEach((item, i) => {
			locationsFilters = item.dataset.filterLocations ? item.dataset.filterLocations.split(',') : [];
			var contains = locationsFilters.find(i => chosenLocationFilters.includes(i));

			if (contains) {
				postSelectionId.push(item.dataset.staffId);
				// console.log('Staff (', item.dataset.staffId,') in selected location(s) exists.');
			}
		});
	}
	if (chosenCatFilters && chosenCatFilters.length) {
		var subFilters = [];
		var latestSelection = [];
		selection.forEach((item, i) => {
			if ((postSelectionId && postSelectionId.length && postSelectionId.includes(item.dataset.staffId)) || (postSelectionId && !postSelectionId.length)) {
				// user has either selected a location, and this item includes the location, or the user has not selected a location. Continue!
				// console.log("Include:", item.dataset.staffId);

				subFilters = item.dataset.filterSubcategories ? item.dataset.filterSubcategories.split(',') : [];
				var contains = subFilters.find(i => chosenCatFilters.includes(i));

				if (contains) {
					latestSelection.push(item.dataset.staffId);
					// console.log('Staff (', item.dataset.staffId,') in selected category(ies) exists.');
				}
			} else {
				// user has selected location, and this item does NOT include the location.
				// console.log("Skip:", item.dataset.staffId);
			}
		});
		postSelectionId = latestSelection;
	}
	// console.log('Ids:', postSelectionId);
	var notFiltered = document.body.querySelectorAll('.staff-item:not(.staff-item.filtered)');
	notFiltered.forEach((item, i) => {
		// temporarily remove all items.
		item.classList.add('filtered');
	});
	postSelectionId.forEach((item, i) => {
		// show the matching items
		var show = document.body.querySelector('.staff-item[data-staff-id="' + item + '"]');
		if (show) {
			show.classList.remove('filtered');
		}
	});
}

function checkClickOutsideFilter (e) {
	if (filter && filter.hasClass('visible')) {
		// console.log('Visible');
		const clickInsideFilter = e.target.closest('.filters');
		if (!clickInsideFilter && (!e.target.hasClass('filter-toggle') && !e.target.closest('.filter-toggle'))) {
			// Clicked outside filter. Close it!
			// console.log('Clicked outside');
			filter.classList.remove('visible');
		}
	}
}
