import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
import AOS from 'aos';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import './components/tabs.js';
import './components/staff.js';
import './components/header.js';

import '../css/app.pcss';

AOS.init({
	// startEvent: 'lazyloaded',
	offset: 100,
	duration: 250,
	easing: 'ease-out'
});

Swiper.use([Navigation, Pagination, Autoplay]);

if (!('object-fit' in document.createElement('a').style)) {
	require('lazysizes/plugins/object-fit/ls.object-fit');
}

Element.prototype.hasClass = function (className) {
	return this.className && new RegExp('(^|\\s)' + className + '(\\s|$)').test(this.className);
};

// Arguments frontpage
var frontpageArguments = document.querySelectorAll('.argument');
if (frontpageArguments && frontpageArguments.length) {
	var interval = setInterval(() => {
		var current = -1;
		frontpageArguments.forEach((item, i) => {
			if (!item.hasClass('hidden')) {
				current = i;
			}
		});
		frontpageArguments[current].classList.add('hidden');
		frontpageArguments[current === (frontpageArguments.length - 1) ? 0 : (current + 1)].classList.remove('hidden');
	}, 5000);

	window.onbeforeunload = function() {
		console.log('unloading');
		clearInterval(interval)
    return;
	};
}


// Add class do parent for lazyloaded images
document.addEventListener('lazyloaded', function (event) {
	var $target = event.target;
	var $parent = $target.parentNode;

	$parent.classList.add('loaded');

	AOS.refresh();
});

// Check elements to create sizes for srcset
window.onload = window.lazySizes.autoSizer.checkElems;

document.addEventListener('DOMContentLoaded', function () {
	let videosArray = document.querySelectorAll('.text-module iframe[src^="https://player.vimeo.com"], .text-module iframe[src^="//player.vimeo.com"], .text-module iframe[src^="https://www.youtube.com/embed/"], .text-module iframe[src^="//www.youtube.com/embed/"]');
	videosArray = Array.prototype.slice.call(videosArray);

	videosArray.map((elem) => {
		let videoHeight = elem.height !== '' ? elem.height : elem.style.height.replace('px', '');
		let videoWidth = elem.width !== '' ? elem.width : elem.style.width.replace('px', '');

		elem.dataset.aspectRatio = videoHeight / videoWidth;
		elem.removeAttribute('height');
		elem.removeAttribute('width');
		elem.removeAttribute('style');
	});

	function resizeVideo () {
		videosArray.map((elem) => {
			if (elem.parentNode) {
				let newWidth = elem.parentNode.offsetWidth;
				elem.width = newWidth;
				elem.height = newWidth * elem.dataset.aspectRatio;
			}
		});
	}

	window.addEventListener('resize', function (evt) {
		resizeVideo();
	});

	resizeVideo();
});

window.addEventListener('load', function () {
	var swipers = document.querySelectorAll('.swiper-container');
	var parent = null;
	var next = null;
	var prev = null;
	var swip = null;
	var allSwipers = [];
	var slp = 1;
	var autoplay = false;
	var duration = window.swiperSlideDuration ? parseInt(window.swiperSlideDuration) * 1000 : 4000; // duration of each slide in autoplay-swipers.
	var pagination = null;

	swipers.forEach((swiper, i) => {
		parent = swiper.closest('.swiper-container-top');
		/* parent (.swiper-container-top) is the absolute top element for each swiper which contains everything for
		* the current swiper. Needs to be present to get the next, prev and pagination html-elements (because
		* multiple swipers on same page needs the html-element for navigation and pagination (in new Swiper(...)) to not interfere with each other)
		*/
		next = parent.querySelector('.swiper-button-next-custom') || null;
		prev = parent.querySelector('.swiper-button-prev-custom') || null;
		pagination = parent.querySelector('.swiper-pagination') || null;
		slp = 1;
		autoplay = false;

		if (swiper.hasClass('swiper-auto-slides')) {
			slp = 'auto';
		} else if (swiper.hasClass('swiper-autoplay')) {
			autoplay = {
				disableOnInteraction: false,
				delay: duration,
			}
		}
		allSwipers.push(new Swiper(swiper, {
			slidesPerView: slp,
			navigation: {
				nextEl: next,
				prevEl: prev,
			},
			pagination: {
	      el: pagination,
				dynamicBullets: true
	    },
			autoplay: autoplay,
			loop: !!autoplay
		}));
	});


	function updateSlides () {
		allSwipers.forEach((swiper, i) => {
			// console.log('update slides:', swiper);
			swiper.update();
		});
	}

	window.addEventListener('resize', function (evt) {
		updateSlides();
		console.log('resized');
	});
});


if (module.hot) {
	module.hot.accept();
}
